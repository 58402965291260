<template>
  <div class="drug-table">
    <div v-if="activeName === $t('consultRoom.visitPrescrib')"
         id="helpBlur"
         class="right-title">药品信息<span style="color: #f00;">（{{ drugList?drugList.length:0 }}）</span></div>
    <el-row class="table-header">
      <el-col v-if="recipeType==2"
              :span="8">{{ $t('consultRoom.drugName') }}</el-col>
      <el-col :span="6">{{ $t('consultRoom.usage') }}</el-col>
      <el-col :span="8">{{ $t('consultRoom.singleDose') }}</el-col>
      <el-col v-if="recipeType==1"
              :span="6">{{ $t('consultRoom.frequency') }}</el-col>
      <el-col v-if="recipeType==1"
              :span="4">{{ $t('consultRoom.num') }}</el-col>
      <el-col v-if="recipeType==2"
              :span="2">{{ $t('consultRoom.operation') }}</el-col>
    </el-row>
    <div class="table-box">
      <div v-for="(item,index) in drugList"
           :key="item.drugId||index"
           class="table-contain">
        <div v-if="recipeType==1"
             :class="activeName === $t('consultRoom.visitPrescrib')?'':'name-Record'"
             class="name-west">
          <el-tooltip v-if="item.drugName&&activeName === $t('consultRoom.visitPrescrib')"
                      content="复制药品名称"
                      class="item"
                      effect="dark"
                      placement="bottom">
            <el-image src="/img/copy.png"
                      fit="cover"
                      style="margin-left: 10px;"
                      @click="copyName(item.drugName+' '+item.specification)" />
          </el-tooltip>
          <span style="margin-right: 5px;">({{ index+1 }})</span>
          <el-tooltip v-if="item.drugName&&activeName === $t('consultRoom.visitPrescrib')"
                      :content="item.drugName"
                      class="item"
                      effect="dark"
                      placement="bottom">
            <span class="drug-name overflow-1"
                  @click="copyName(item.drugName+' '+item.specification)">{{ item.drugName }}</span>
          </el-tooltip>
          <el-popover v-if="activeName === $t('consultRoom.medicalRecord')"
                      :title="$t('recipeTemplate.selectDrugs')"
                      placement="left"
                      width="600"
                      trigger="click">
            <div slot="reference"
                 class="search-drug">
              <el-input v-model="item.drugName"
                        :placeholder="recipeType==1?$t('consultRoom.drugSearchTip1'):$t('consultRoom.drugSearchTip2')"
                        style="width: 100%;"
                        size="mini"
                        @focus="fetchDrugsData(item)"
                        @input="debounce(() => { fetchDrugsData(item) }, 1000, false)()" />
            </div>
            <avue-crud ref="crud"
                       :page.sync="page"
                       :data="drugDataList"
                       :table-loading="tableLoading"
                       :option="drugTableOption"
                       :row-class-name="()=>{return 'pointer'}"
                       @row-click="selectDrug(index,$event)" />
          </el-popover>
          <span v-if="item.specification&&activeName === $t('consultRoom.visitPrescrib')"
                class="drug-spec"
                @click="copyName(item.drugName+' '+item.specification)">【{{ item.specification }}】</span>
          <el-col :span="2">
            <el-tooltip :content="$t('common.delete')"
                        class="item"
                        effect="dark"
                        placement="bottom">
              <i class="el-icon-delete delete"
                 @click="deleteItem(index)" />
            </el-tooltip>
          </el-col>
        </div>
        <!-- <div v-if="recipeType==1">{{ $t('consultRoom.usage') }}：{{ item.usage }}、{{ $t('consultRoom.every') }}{{ item.dosage }}{{ item.dosageUnit }}、{{ item.frequencyName }}</div> -->
        <el-row :gutter="2"
                class="table-content">
          <template v-if="recipeType==2">
            <el-col v-show="activeName === $t('consultRoom.visitPrescrib')"
                    :span="8"
                    style="text-align:left;">
              <el-image src="/img/copy.png"
                        title="复制"
                        style="height: 18px;width: 18px;vertical-align: middle;cursor: pointer;margin:0 5px;"
                        fit="cover"
                        @click="copyName(item.drugName+' '+item.specification)" />
              <span style="margin-right: 5px;">({{ index+1 }})</span>
              <el-tooltip :content="item.drugName"
                          class="item"
                          effect="dark"
                          placement="bottom">
                <span @click="copyName(item.drugName+' '+item.specification)">{{ item.drugName }}</span>
              </el-tooltip>
            </el-col>
            <el-col v-show="activeName === $t('consultRoom.medicalRecord')"
                    :span="8">
              <el-popover :title="$t('recipeTemplate.selectDrugs')"
                          :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                          placement="left"
                          width="600"
                          trigger="click">
                <div slot="reference"
                     class="search-drug">
                  <el-input v-model="item.drugName"
                            :placeholder="recipeType==1?$t('consultRoom.drugSearchTip1'):$t('consultRoom.drugSearchTip2')"
                            style="width: 100%;"
                            size="mini"
                            @focus="fetchDrugsData(item)"
                            @input="debounce(() => { fetchDrugsData(item) }, 1000, false)()" />
                </div>
                <avue-crud ref="crud"
                           :page.sync="page"
                           :data="drugDataList"
                           :table-loading="tableLoading"
                           :option="drugTableOption"
                           :row-class-name="()=>{return 'pointer'}"
                           @row-click="selectDrug(index,$event)" />
              </el-popover>
            </el-col>
          </template>
          <el-col :span="6">
            <el-tooltip :content="item.usage"
                        class="item"
                        effect="dark"
                        placement="bottom">
              <el-select v-model="item.usage"
                         filterable
                         style="width: 80%;"
                         size="mini"
                         class="usage-select"
                         @change="saveStorage"
                         @visible-change="fetchUsage">
                <el-option v-for="(item,index) in usageList"
                           :key="index"
                           :label="item.name"
                           :value="item.name" />
              </el-select>
            </el-tooltip>

          </el-col>
          <el-col :span="8">
            <el-input v-model="item.dosage"
                      :title="item.dosage"
                      :ref="'dosageEl'+index"
                      class="dosage-input"
                      size="mini"
                      style="width: 45%;"
                      @input="formatNum(item.dosage,index,recipeType)"
                      @blur="formatNumBlur(index)" />
            <el-tooltip :content="item.dosageUnit"
                        class="item"
                        effect="dark"
                        placement="bottom">
              <el-select v-model="item.dosageUnit"
                         :title="item.dosageUnit"
                         filterable
                         size="mini"
                         style="width: 45%;"
                         @change="saveStorage">
                <el-option v-for="(item,index) in singleList"
                           :key="index"
                           :label="item"
                           :value="item" />
              </el-select>
            </el-tooltip>

          </el-col>
          <template v-if="recipeType==1">
            <el-col :span="6">
              <el-tooltip :content="item.frequencyName"
                          class="item"
                          effect="dark"
                          placement="bottom">
                <el-select v-model="item.frequencyName"
                           :ref="'frequencyEl'+index"
                           size="mini"
                           filterable
                           style="width: 90%;"
                           @change="saveStorage('frequency',index)">
                  <el-option v-for="(item,index) in frequencyList"
                             :key="index"
                             :label="item.name"
                             :value="item.name" />
                </el-select>
              </el-tooltip>

              <!-- <el-select v-model="item.freqValue"
                         filterable
                         style="width: 60%;"
                         @change="saveStorage('frequency',index)">
                <el-option v-for="(item,index) in frequencyList"
                           :key="index"
                           :label="item.name"
                           :value="item.name" />
              </el-select>
              <el-input v-model="item.freqUnit"
                        style="width: 40%;"
                        class="input-with-select"
                        @input="(val)=>{item.freqUnit = val.replace(/[^\d]/g,'')}"
                        @blur="saveStorage">
                <template slot="append">次</template>
              </el-input> -->
            </el-col>
            <el-col :span="4">
              <el-input-number v-model="item.buyCount"
                               :title="item.buyCount"
                               :controls="false"
                               :min="1"
                               :precision="0"
                               size="mini"
                               style="width:70%;"
                               @blur="saveStorage" />
            </el-col>
          </template>
          <template v-else>
            <el-col :span="2">
              <el-tooltip :content="$t('common.delete')"
                          class="item"
                          effect="dark"
                          placement="bottom">
                <i class="el-icon-delete delete"
                   @click="deleteItem(index)" />
              </el-tooltip>
            </el-col>
          </template>
        </el-row>
      </div>
    </div>
    <!-- <div v-if="activeName === $t('consultRoom.medicalRecord')"
         class="table-btn">
      <el-button size="medium"
                 type="primary"
                 icon="el-icon-circle-plus-outline"
                 @click="addDrug">{{ $t('consultRoom.addDrugs') }}</el-button>
    </div> -->
    <div v-if="activeName === $t('consultRoom.medicalRecord')"
         class="table-btn">
      <el-button size="medium"
                 type="primary"
                 icon="el-icon-circle-plus-outline"
                 @click="addDrug">{{ $t('consultRoom.addDrugs') }}</el-button>
    </div>
    <div v-if="currentUserId"
         class="supple-instruct">
      <div class="right-title">处方补充说明</div>
      <el-input v-model="currentUserData.suppleInstruct"
                :rows="2"
                type="textarea"
                resize="none"
                maxlength="50"
                show-word-limit
                placeholder="请输入处方补充说明"
                @blur="changeData" />
    </div>
  </div>
</template>

<script>
import {
  drugTableOption
} from '@/const/crud/recipeTemplate'
import { commonApi } from '@/api/commonApi'
import { recipeTemplateApi } from '@/api/functionApi'
import { debounce } from '@/util'
import socketPublic from '@/socketMsg/socketPublic.js'
import inquiry from '@/socketMsg/inquiry.js'
import checkDrug from '@/socketMsg/checkDrug.js'

export default {
  name: 'DrugTable',
  props: {
    recipeType: {
      type: Number,
      default: 1
    },
    activeName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      debounce,
      drugTableOption,
      usageList: [],
      singleList: [],
      frequencyList: [],
      tableLoading: false,
      tableData: [],
      page: {
        total: 1, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        ascs: [], // 升序字段
        descs: [] // 降序字段
      },
      drugDataList: [],
      suppleInstruct: '',
      drugList: []
    }
  },
  computed: {
    // drugList () {
    //   return socketPublic.state.currentUserData.drugList
    // },
    currentUserData() {
      return socketPublic.state.currentUserData
    },
    currentUserId() {
      return socketPublic.state.currentUserId
    }
  },
  watch: {
    currentUserData: {
      handler(val) {
        this.drugList = this.deepClone(val.drugList)
      },
      deep: true
    },
    currentUserId(newValue) {
      this.suppleInstruct = ''
    }
  },
  created() {
    this.fetchFrequency()
    this.fetchDosageUnit()
  },
  methods: {
    changeData(e) {
      inquiry.commonSetCache({
        name: 'currentUserData_' + this.currentUserId,
        content: this.currentUserData
      })
    },
    async copyName(name) {
      try {
        await this.$Clipboard({
          text: name
        })
        this.$message.success('复制成功')
      } catch (err) {
        console.error(err)
      }
    },
    // 药品列表
    async fetchDrugsData(row) {
      try {
        if (!row.drugName) return
        this.tableLoading = true
        const params = {
          keyword: row.drugName,
          page: this.page.currentPage,
          limit: this.page.pageSize,
          drugType:
            this.recipeType === 1
              ? 1
              : this.recipeType === 2
                ? 3
                : ''
        }
        if (this.currentUserData.companyType == 2 && this.currentUserData.visitType) params.companyCode = this.currentUserData.companyCode
        const res = this.currentUserData.companyType == 2 && this.currentUserData.visitType ? await recipeTemplateApi.fetchThirdPartyGrugs(params) : await recipeTemplateApi.fetchGrugs(params)
        this.drugDataList = res.data.data || []
        this.page.total = res.data.count
        this.tableLoading = false
      } catch (err) {
        this.tableLoading = false
        console.error(err)
      }
    },
    saveStorage() {
      const currentUserData = this.deepClone(this.currentUserData)
      currentUserData.drugList = this.drugList
      inquiry.commonSetCache({
        name: 'currentUserData_' + this.currentUserId,
        content: currentUserData
      })
      checkDrug.newCheckDrugs()
    },
    // 点击药品
    selectDrug(index, row) {
      this.drugList[index].drugId = row.drugId
      this.drugList[index].drugName = row.drugName || ''
      this.drugList[index].drug_name = row.drugName || ''
      this.drugList[index].usage = row.usage || ''
      this.drugList[index].specification = row.specification || ''
      this.drugList[index].diagnose = row.diagnose
      this.drugList[index].isSpecial = row.isSpecial
      this.drugList[index].addDrugName = row.drugName + ' ' + row.specification
      this.saveStorage()
    },
    // 添加药品
    addDrug() {
      if (this.recipeType == 1 && this.drugList.length >= 5) {
        this.$message.warning(this.$t('consultRoom.addDrugTip'))
        return
      }
      this.drugList.push({
        barCode: '',
        buyCount: 1,
        buyUnit: '盒',
        dayCount: 3,
        day_times: 3,
        doctorAdvice: '',
        dosage: '',
        dosageUnit: '',
        drugApprovalNum: '',
        drugId: '',
        drugName: '',
        drugType: this.recipeType,
        drug_name: '',
        frequency: '',
        frequencyName: '',
        isSpecial: '',
        packingUnit: '',
        price: 0,
        specification: '',
        usage: '',
        diagnose: '',
        warningTxt: ''
      })
      this.saveStorage()
    },
    // 获取通用药品用法
    async fetchUsage(val) {
      if (!val) return
      try {
        const res = await commonApi.fetchUsage({
          type: this.recipeType == 1 ? 1 : 2
        })
        this.usageList = res.data.data
      } catch (err) {
        console.error(err)
      }
    },
    // 获取通用频次
    async fetchFrequency() {
      try {
        const res = await commonApi.fetchFrequency()
        this.frequencyList = res.data.data
      } catch (err) {
        console.error(err)
      }
    },
    // 获取通用用量单位
    async fetchDosageUnit() {
      try {
        const res = await commonApi.fetchDosageUnit()
        this.singleList = res.data.data
      } catch (err) {
        console.error(err)
      }
    },
    // saveStorage (type, index) {
    //   this.$emit('saveStorage')
    // },
    // 删除药品
    async deleteItem(index) {
      if (this.drugList.length === 1) {
        this.$message.warning('不能删除，药品至少要有一个!')
        return
      }
      const item = this.drugList.splice(index, 1)
      const currentUserData = this.deepClone(this.currentUserData)
      currentUserData.deleteDrugList.push(item[0])
      currentUserData.drugList = this.drugList
      inquiry.commonSetCache({
        name: 'currentUserData_' + this.currentUserId,
        content: currentUserData
      })
      this.saveStorage()
    },
    formatNum(val, keys, type) {
      let temp = val.toString()
      temp = temp.replace(/。/g, '.')
      temp = temp.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, '') // 验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, '') // 只保留第一个, 清除多余的
      temp = temp.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      if (type === 1) {
        temp = temp.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3') // 只能输入4个小数
      } else {
        temp = temp.replace(/^(\-)*(\d+)\.(\d+).*$/, '$1$2.$3') // 不限制多少为小数
      }

      this.drugList[keys].dosage = temp
    },
    formatNumBlur(keys) {
      const reg2 = /(?:\.0*|(\.\d+?)0+)$/

      this.drugList[keys].dosage = this.drugList[keys].dosage.replace(reg2, '$1')

      this.saveStorage()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .avue-crud__menu {
  display: none;
}
.input-with-select {
  /deep/ .el-input-group__append {
    background-color: #fff;
    padding: 0 2px;
  }
}
// /deep/ .el-input input {
//   text-align: center;
// }
.drug-table {
  margin-top: 10px;
  padding: 0 12px;
  .right-title {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .table-header {
    height: 38px;
    line-height: 38px;
    text-align: center;
    background-color: #e0f0fd;
    font-size: 14px;
    color: #3b94ff;
    font-weight: 600;
  }
  .table-box {
    .table-contain {
      background-color: #f3f4f6;
      font-size: 14px;
      &:hover {
        background-color: #f2f9ff;
      }
      .name-west {
        padding: 10px 0;
        display: flex;
        align-items: center;

        /deep/ .el-image {
          height: 18px;
          width: 18px;
          vertical-align: -4px;
          cursor: pointer;
          margin-right: 4px;
          flex-shrink: 0;
        }
        .drug-name {
          margin-right: 10px;
          width: fit-content;
        }
        .drug-spec {
          flex-shrink: 0;
        }
        i {
          color: #e20000;
          font-size: 16px;
          cursor: pointer;
          margin-left: 10px;
        }
      }
      .name-Record {
        & > span {
          flex: 1;
        }
      }
      .content-total,
      .table-content {
        .el-col {
          padding: 0 6px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          /deep/ .el-input__inner {
            padding: 0 5px;
            border-radius: 0;
            text-overflow: ellipsis;
          }
          /deep/ .el-select {
            .el-input__inner {
              padding-right: 20px;
            }
            .el-select__caret {
              color: #3b94ff;
            }
          }
          /deep/ .el-input__suffix {
            right: 0;
          }
          .dosage-input {
            /deep/ .el-input__inner {
              border-right: none;
              text-align: right;
            }
          }
          .usage-select {
            /deep/ .el-input__inner {
              text-align: center;
            }
          }
          .delete {
            color: #e20000;
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
      .table-content {
        height: 34px;
        line-height: 32px;
        text-align: center;
      }
      .content-total {
        margin-top: 2px;
        color: #999;
        .remark {
          text-indent: 1em;
          & > div {
            height: 20px;
            line-height: 20px;
          }
        }
        .el-row {
          height: 100%;
        }
        &.total-chinese {
          padding: 0 12px;
        }
      }
      .warnings {
        color: #e20000;
      }
    }
  }
  .table-btn {
    margin-top: 10px;
  }
  .supple-instruct {
    font-size: 14px;
    margin-top: 20px;
    /deep/ .el-textarea__inner {
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 1550px) {
  .drug-table {
    margin-top: 10px;
    .table-box {
      .table-contain {
        .table-content,
        .content-total {
          // height: 40px;
          // line-height: 40px;
          .el-col {
            // padding: 0 2px;
            /deep/ .el-input__inner {
              // height: 36px;
            }
          }
        }
      }
    }
  }
}
</style>
