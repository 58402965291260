<template>
  <div class="aide-home">
    <div class="aide-home-result">
      <div v-if="checkDone == 0"
           class="aide-home-checking">
        <div>
          风险检测中<span><i class="el-icon-loading" /></span>
        </div>
      </div>
      <div v-if="currentData.recipe_type==2"
           class="aide-home-done">
        <div class="result-top">
          <div class="result-check">
            <div class="result-logo">
              <img :src="unpassSvg"
                   width="36"
                   height="36">
            </div>
            <div class="unpass result-text">中药不支持用药检测</div>
          </div>
        </div>
      </div>
      <div v-if="checkDone == 1&&currentData.recipe_type!=2"
           class="aide-home-done">
        <div :style="{backgroundColor: forbidLevel == 1?'#E7FFEA':forbidLevel == 0 || forbidLevel == 2?'#FFE5E5':forbidLevel == 3?'#FFEDD3':''}"
             class="result-top">
          <div class="result-check">
            <div class="result-logo">
              <img :src="forbidLevel == 1?passSvg:forbidLevel == 0 || forbidLevel == 2?unpassSvg:forbidLevel == 3?payAttentionSvg:''"
                   width="36"
                   height="36">
            </div>
            <div :class="forbidLevel == 1?'pass':forbidLevel == 0 || forbidLevel == 2?'unpass':forbidLevel == 3?'c-warning':''"
                 class="result-text">
              {{ forbidLevel == 1?'检测通过':forbidLevel == 0 ?'禁止通过':forbidLevel == 2 ?'存在风险':forbidLevel == 3?'重点关注':'' }}
            </div>
          </div>
        </div>
        <div v-if="showError.length > 0||showYellow.length > 0"
             class="result-done check-title">
          处方存在以下风险：
        </div>
        <div v-if="showError.length > 0||showYellow.length > 0"
             class="check-box">
          <div v-if="showError.length > 0"
               class="result-list">
            <div v-for="(item, index) in showError"
                 :key="index"
                 class="result-reason">
              {{ index + 1 }}、{{ item }}
            </div>
          </div>
          <div v-if="showError.length > 0&&checkDone == 1 && showYellow.length > 0"
               class="line-row" />
          <!-- <div class="result-done"
             v-if="checkDone == 1 && showYellow.length > 0">
          注意事项：
        </div> -->
          <div v-if="checkDone == 1 && showYellow.length > 0"
               class="result-list">
            <div v-for="(item, index) in showYellow"
                 :key="index"
                 class="result-warnig"
                 v-html="index + 1 + '、' + item" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="druguseTip&&druguseTip.length>0||useforbidList&&useforbidList.length>0||excessList&&excessList.length>0||outFreList&&outFreList.length>0 ||outDoseList&&outDoseList.length>0||noUseMethodList&&noUseMethodList.length>0"
         class="aide-home-check">
      <div class="aide-title check-title">需确认内容</div>
      <div class="aide-check-body check-box"
           style="padding-top: 0;">
        <div v-if="druguseTip&&druguseTip.length>0"
             class="check-item">
          <div class="new-title">
            <span class="weight-bold">与推荐的药品用法用量不一致</span>
          </div>
          <div class="aide-check-item no-border">
            <div v-for="(item, index) in druguseTip"
                 :key="index"
                 class="advise-result-list">
              <div class="check-item-title">
                【<span class="title-left drug-name">{{ item.commDrug }}</span>】
              </div>
              <template>
                <div class="drug-use-method">
                  适用范围：{{ item.syAge }}
                </div>
                <div class="drug-use-method">
                  <el-tooltip :content="`${item.usageMethod}，${item.doseValue + item.doseUnit}，${ item.freqUnit }`"
                              class="item"
                              effect="dark"
                              placement="bottom">
                    <div class="overflow-1">用法用量：{{ item.usageMethod }}，{{ item.doseValue + item.doseUnit }}，{{ item.freqUnit }}</div>
                  </el-tooltip>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div v-if="useforbidList&&useforbidList.length>0"
             class="check-item">
          <div class="new-title">
            <span class="weight-bold">药品用法用量不完善</span>
            <div class="new-title-right">
              <span class="unpass"
                    style="font-weight: bold;">风险：禁开</span>
            </div>
          </div>
          <div class="aide-check-item no-border">
            <span v-for="(fItem,fIndex) in useforbidList"
                  :key="fIndex">【<span class="drug-name">{{ fItem.drugName }}</span>】{{ fIndex!==useforbidList.length-1?'、':'' }}</span>
            用法用量不完善，无法开方，请根据实际情况完善后点击
            <span class="drug-name"
                  style="margin-left: 6px;">临时放行</span>
          </div>
        </div>
        <div v-if="excessList&&excessList.length>0"
             class="check-item">
          <div class="new-title">
            <span class="weight-bold">药品超量问题</span>
          </div>
          <div class="aide-check-item no-border">
            <div v-for="(item, index) in excessList"
                 :key="index"
                 class="advise-result-list">
              <div class="excess-limit">
                【<span class="drug-name">{{ item.comName }}</span>】
                超过规定使用量，当前限购数量为{{ item.maxBuyCount }}件{{ item.maxUseDay?`（用药天数为${item.maxUseDay}天）`:'' }}
              </div>
              <div class="excess-tip clearfix">
                <span>您好，按照目前互联网诊疗规范，【<span class="drug-name">{{ item.comName }}</span>】药品超量了，单方先开{{ item.maxBuyCount }}件。</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="outFreList&&outFreList.length>0 ||outDoseList&&outDoseList.length>0"
             class="check-item">
          <div class="new-title">
            <span class="weight-bold">药品用法用量超出推荐范围</span>
          </div>
          <div class="aide-check-item no-border">
            <div v-for="(item, index) in outDoseList"
                 :key="index"
                 class="advise-result-list">
              <div class="out-tip">
                【<span class="drug-name">{{ item.comName }}</span>
                】单次用量超出推荐范围（{{ item.minLimit&&item.maxLimit?`${item.minLimit+item.unit}~${item.maxLimit+item.unit}`:item.minLimit?`单次最小用量为${item.minLimit+item.unit}`:item.maxLimit?`单次最大用量为${item.maxLimit+item.unit}`:'' }}），请谨慎开方
              </div>
            </div>
            <div v-for="(item, index) in outFreList"
                 :key="index"
                 class="advise-result-list">
              <div class="out-tip">
                【<span class="drug-name">{{ item.comName }}</span>
                】用药频次推荐范围（{{ item.minLimit&&item.maxLimit?`${item.unit+item.minLimit}~${item.maxLimit}次`:item.minLimit?`最小频次为${item.unit+item.minLimit}次`:item.maxLimit?`最大频次为${item.unit+item.maxLimit}次`:'' }}），请谨慎开方
              </div>
            </div>
          </div>
        </div>
        <div v-if="noUseMethodList&&noUseMethodList.length>0"
             class="check-item">
          <div class="new-title">
            <span class="weight-bold">该药品的用法用量暂时无法检测</span>
          </div>
          <div class="aide-check-item no-border">
            <div class="advise-result-list">
              <span v-for="(item, index) in noUseMethodList"
                    :key="index">
                【<span class="drug-name">{{ item.comName }}</span>】
              </span>
            </div>
            <div class="advise-result-list">该药品的用法用量暂时无法检测，请仔细查阅药品说明书，并根据实际情况进行适当调整</div>
          </div>
        </div>
      </div>
    </div>
    <div class="aide-home-check">
      <div class="aide-title check-title">用药人信息</div>
      <div class="aide-check-body check-box">
        <el-empty v-if="checkDone != 1"
                  description="暂无用药人信息" />
        <div v-else
             class="check-user">
          <el-row>
            <el-col :span="12"
                    class="check-col">
              <el-tooltip :content="currentData.patient_name"
                          class="item"
                          effect="dark"
                          placement="bottom">
                <div class="check-left overflow-1">姓名：{{ currentData.patient_name }}</div>
              </el-tooltip>
              <div :class="testResults.isUserName == 0 ? 'pass' : 'unpass'"
                   class="check-right">
                {{ testResults.isUserName == 0 ? '通过' : '不通过' }}
              </div>
            </el-col>
            <el-col :span="12"
                    class="check-col">
              <div class="check-left">
                肝功能：<span :class="patientInfo.gang != '正常' ? 'unpass' : ''">{{ patientInfo.gang }}</span>
              </div>
              <div v-if="patientInfo.gang != '正常'"
                   class="check-right"
                   v-html="chtml[testResults.isLiverDamage]" />
              <div v-if="patientInfo.gang == '正常'"
                   class="check-right"
                   v-html="chtml[0]" />
            </el-col>
            <el-col :span="12"
                    class="check-col mgtb6">
              <div class="check-left">年龄：{{ currentData.age }}</div>
              <div :class="testResults.isAge == 1 ? 'pass' : 'unpass'"
                   class="check-right">
                {{ testResults.isAge == 1 ? '通过' : '不通过' }}
              </div>
            </el-col>
            <el-col :span="12"
                    class="check-col mgtb6">
              <div class="check-left">
                肾功能：<span :class="patientInfo.shen != '正常' ? 'unpass' : ''">{{ patientInfo.shen }}</span>
              </div>
              <div v-if="patientInfo.shen != '正常'"
                   class="check-right"
                   v-html="chtml[testResults.isRenalDamage]" />
              <div v-if="patientInfo.shen == '正常'"
                   class="check-right"
                   v-html="chtml[0]" />
            </el-col>
            <el-col :span="12"
                    class="check-col">
              <div class="check-left">性别：{{ currentData.patient_gender == 1?'男':'女' }}</div>
              <div :class="testResults.isSex == 1 ? 'pass' : 'unpass'"
                   class="check-right">
                {{ testResults.isSex == 1 ? '通过' : '不通过' }}
              </div>
            </el-col>
            <el-col :span="12"
                    class="check-col">
              <div class="check-left">
                孕哺情况：<span :class="patientInfo.pregnancy != '否认' ? 'unpass' : ''">{{ patientInfo.pregnancy }}</span>
              </div>
              <div :class="patientInfo.pregnancy != '否认' &&(testResults.isDisableWoman == 2 ||testResults.isDisableGestational == 2)? 'unpass': 'pass'"
                   class="check-right">
                {{ patientInfo.pregnancy != '否认' &&(testResults.isDisableWoman == 2 ||testResults.isDisableGestational == 2)? '不通过': '通过' }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="aide-home-check">
      <div class="aide-title check-title">疾病信息</div>
      <div class="aide-check-body check-box">
        <div class="check-dignose">
          <el-row>
            <el-col :span="24"
                    class="check-col">
              <el-tooltip :content="dignoseList.join('、')"
                          class="item"
                          effect="dark"
                          placement="bottom">
                <div class="check-left overflow-1">诊断：{{ dignoseList.join('、') }}</div>
              </el-tooltip>
              <div v-if="dignoseList.length > 0"
                   :class="checkDignose == 1 ? 'pass' : 'unpass'"
                   class="check-right">
                {{ checkDignose == 1 ? '通过' : '不通过' }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <el-backtop target=".aide-home">
      <div class="back-top">
        <el-image src="/svg/back_to_top.svg" />
        <div>返回顶部</div>
      </div>
    </el-backtop>
  </div>
</template>
<script>
import { otherCheckApi } from '@/api/otherCheckApi'
import { recipeRecordApi } from '@/api/functionApi'

export default {
  name: 'DrugTest',
  data () {
    return {
      passSvg: '/svg/pass.svg',
      unpassSvg: '/svg/unpass.svg',
      payAttentionSvg: '/svg/pay_attention.svg',
      arrowUpPng: '/img/arrow_up.png',
      arrowDownPng: '/img/arrow_down.png',
      drugActiveObj: '1',
      drugActiveList: [],
      drugDetailList: [],
      druguseTip: [],
      useError: [],
      drugDetailObj: {
        drugBase: {}
      },
      shtml: [
        '可用',
        '<b style="color:#fd9c12">慎用</b>',
        '<b style="color:#e20000">禁用</b>',
        '<b style="color:#fd9c12">慎用</b>'
      ],
      chtml: [
        '<span style="color:#66cc66">通过</span>',
        '<span style="color:#fd9c12">需注意</span>',
        '<span style="color:#e20000">不通过</span>',
        '<span style="color:#fd9c12">需注意</span>'
      ],
      testResults: {
        isUserName: 0, // 姓名检测 0 正常 1异常
        isSex: 1, // 性别检测 0.不合适 1.合适 2.不确定
        isAge: 1, // 年龄检测  0.不合适 1.合适 2.不确定
        isDiagnoses: [], // 诊断
        isLiverDamage: 0, // 肝功能异常0.可用 1.慎用 2.禁用 3.不确定
        isRenalDamage: 0, // 肾功能异常0.可用 1.慎用 2.禁用 3.不确定
        isDisableChildren: 0, // 儿童用药0.可用 1.慎用 2.禁用
        isDisableOld: 0, // 老年用药0.可用 1.慎用 2.禁用
        isDisableWoman: 0, // 妊娠孕妇用药0.可用 1.慎用 2.禁用
        isDisableGestational: 0, // 哺乳期用药0.可用 1.慎用 2.禁用
        needPrescription: 0 // 处方凭证  0.不需要 1.需要
      },
      checkDone: 2,
      pass: 1,
      checkDignose: 1,
      showError: [],
      showYellow: [],
      kfhMust: [],
      callPhone: false,
      isyidaosu: false,
      showUpdate: false,
      isjinzhi: [], // 司美格鲁肽注射液禁忌疾病
      showMore: false,
      drugUseMethodList: [], // 药品检测后说明书中的药品用法用量信息列表
      zdforbidState: false, // 诊断禁止
      useforbidList: [], // 用法用量缺失药品列表
      useforbidTexts: [], // 用法用量缺失的处理内容
      forbidLevel: 1, // 整体检测结果等级 0 禁止 1 通过 2 有风险，3 需关注
      // qzfangxing: false, // 强制放行
      pauseType: false, // 未在待开方状态
      isUserCheck: false,
      isDignoseCheck: false,
      checkTimer: null,
      checkNum: 0,
      outDoseList: [], // 药品用量超出推荐范围
      outFreList: [], // 药品频次超出推荐范围
      excessList: [], // 药品超量
      noUseMethodList: [], // 药品用法用量缺失
      isChild: 0, // 儿童
      isOldPeople: 0, // 老人
      checkList: [],
      currentData: {},
      isLoading: false,
      drinkFlags3: [],
      dignoseList: [],
      patientInfo: {}
    }
  },
  watch: {
    drugActiveList: {
      handler (val) {
        if (val.length > 0) {
          this.drugDetailObj = this.drugActiveList[this.drugActiveObj]
        }
      },
      deep: true // 深度监听
    },
    '$route.query.recipeCode': {
      handler (val) {
        if (val) {
          this.fetchRecipeDetail()
        } else this.clearDetect()
      },
      immediate: true
    }
  },
  mounted () {
    // 接收父页面消息
    window.addEventListener('message', (event) => {
      if (event.data === 'getData') {
        // 向父页面发送数据
        window.parent.postMessage({
          type: 'data',
          content: this.forbidLevel
        }, '*')
      }
    })
  },
  methods: {
    async fetchRecipeDetail () {
      try {
        this.isLoading = true
        const res = await recipeRecordApi.fetchRecipeDetail({
          recipeCode: this.$route.query.recipeCode,
          lang: 'cn'
        })
        const data = res.data.data
        data.drugs.forEach(item => {
          item.buyCount = item.buy_count
          item.buyUnit = item.buy_unit
          item.dosageUnit = item.dosage_unit
          item.drugId = item.drug_id
          item.drugName = item.drug_name
          item.frequencyName = item.frequency_name
          item.packingUnit = item.packing_unit
        })
        this.currentData = data
        const diagnoseInfo = this.currentData.custom_diagnose || this.currentData.diagnose_info
        this.dignoseList = diagnoseInfo ? diagnoseInfo.split(';') : []
        this.patientInfo = this.currentData.disease ? this.currentData.disease : {}
        this.checkDrugs()
        this.isLoading = false
      } catch (err) {
        console.error(err)
        this.isLoading = false
      }
    },
    // 新版本用药检测
    async checkDrugs (type) {
      try {
        // const res = await otherCheckApi.getToken({})
        for (const i in drugJson) {
          drugJson[i].jianyi = ''
        }

        const drugJson = this.currentData.drugs
        const drugName = []
        let drug = []
        const drugs = []
        for (const i in drugJson) {
          const single = drugJson[i]
          // const dayTimes = 1
          var regex = /(.+?)(?:（|\()/
          var result1 = regex.exec(single.drugName)
          var commonDrug = result1 ? result1[1].trim() : single.drugName.trim()
          drugName.push(commonDrug)
          drug = {
            comName: commonDrug,
            specs: single.specification,
            brand: '',
            curdrugname: commonDrug,
            searchkey: commonDrug + '_' + single.specification,
            usage: single.usage,
            frequencyUnit: single.frequencyName,
            frequencyValve: single.frequency,
            doseUnit: single.dosageUnit,
            doseValue: single.dosage,
            // days: single.dayCount * single.buy_count,
            count: single.buyCount,
            unit: single.packingUnit,
            frequencyDict: 'drug_frequency_fixed',
            frequencyTimes: 1
          }
          drugs.push(drug)
        }

        const label = []
        const patientInfo = this.patientInfo

        if (patientInfo.pregnancy != '否认') {
          // 哺乳
          label.push('LACTATION')
        }
        if (patientInfo.gang != '正常') {
          label.push('HYPOHEPATIA')
        }
        if (patientInfo.shen != '正常') {
          label.push('RENALINADEQUACY')
        }
        const diagnoseInfo = this.currentData.custom_diagnose || this.currentData.diagnose_info
        const datajson = {
          userName: this.currentData.patient_name,
          age: this.currentData.age,
          diagnose: diagnoseInfo ? diagnoseInfo.split(';').join(',') : '空诊断', // +',鼻窦炎',
          sex: this.currentData.patient_gender == 1 ? '男' : '女',
          buyAmount: '',
          label: label,
          allergyInfo: this.patientInfo.allergyContent,
          familyHistory: this.patientInfo.illContent,
          pastMedicalHistory: this.patientInfo.pastContent,
          weight: '',
          drugUseMethodFroms: drugs,
          platform: 'yinye'
          // token: drugtoken
        }
        const res = await otherCheckApi.checkData(datajson)
        const data = res.data
        if (data) {
          if (data.code == 0) {
            if (type) this.$message.success('检测成功')
            this.checkList = data.data
            this.$store.commit('setCheckDrugData', data)
            this.clearDetect()
            this.getDrugResult()
            this.getError()
            this.checkDrugUseMethod(this.currentData.drugs)
          } else {
            this.$message.error('药品检测失败')
          }
        } else {
          this.$message.error('药品检测失败')
        }
      } catch (err) {
        console.error(err)
      }
    },
    // 判断药品用法用量是否完整
    checkDrugUseMethod (drugList) {
      if (this.currentData.recipe_type == 2) {
        this.useforbidList = []
        return
      }
      this.useforbidList = []
      // let useforbidTexts = []
      drugList.forEach((item) => {
        if (!item.usage || !item.dosage || !item.dosageUnit || !item.frequencyName) {
          this.useforbidList.push(item)
        }
      })
    },
    switchDrug (val) {
      const swtichKey = val.index
      this.drugDetailObj = this.drugActiveList[swtichKey]
    },
    // 未接诊情况下，清理页面内容
    clearDetect () {
      this.testResults = {
        isUserName: 0, // 姓名检测 0 正常 1异常
        isSex: 1, // 性别检测 0.不合适 1.合适 2.不确定
        isAge: 1, // 年龄检测  0.不合适 1.合适 2.不确定
        isDiagnoses: [], // 诊断
        isLiverDamage: 0, // 肝功能异常0.可用 1.慎用 2.禁用 3.不确定
        isRenalDamage: 0, // 肾功能异常0.可用 1.慎用 2.禁用 3.不确定
        isDisableChildren: 0, // 儿童用药0.可用 1.慎用 2.禁用
        isDisableOld: 0, // 老年用药0.可用 1.慎用 2.禁用
        isDisableWoman: 0, // 妊娠孕妇用药0.可用 1.慎用 2.禁用
        isDisableGestational: 0, // 哺乳期用药0.可用 1.慎用 2.禁用
        needPrescription: 0 // 处方凭证  0.不需要 1.需要
      }
      this.checkDone = 2
      this.drugDetailObj = {
        drugBase: {}
      }
      this.showYellow = []
      this.showError = []
      this.showUpdate = false
      this.drugUseMethodList = []
      this.drugActiveList = []
      this.druguseTip = []
      this.drugActiveObj = '0'
      this.excessList = []
      this.outDoseList = []
      this.outFreList = []
      this.noUseMethodList = []
      this.focuses = []
      this.drinkFlags3 = []
      this.isChild = 0
      this.isOldPeople = 0
    },
    getDrugResult (res) {
      // const checkCode = this.checkDrugData.code
      const checkList = this.checkList
      const patientInfo = this.patientInfo
      if (!checkList || !checkList.length) {
        return // 未检查
      }
      this.drugActiveList = []
      const drugUseMethodList = []
      let drugFrom = {}
      const useError = []
      const druguseTip = []
      let showUpdate = false
      checkList.forEach((item, index) => {
        const { isAge, isSex, isDiagnoses, isUserName, needPrescription, age, sex,
          drugBase, isDisableChildren, isDisableOld, isDisableWoman, isDisableGestational,
          isLiverDamage, isRenalDamage, isDisableAllergy, drugUseMethod, from,
          maxUseData, doseOverLimitData, dayFreOverLimitData
        } = item
        if (maxUseData) this.excessList.push({ ...maxUseData, ...{ comName: item.comName } })
        if (doseOverLimitData) this.outDoseList.push({ ...doseOverLimitData, ...{ comName: item.comName } })
        if (dayFreOverLimitData) this.outFreList.push({ ...dayFreOverLimitData, ...{ comName: item.comName } })
        drugFrom = from
        if (drugUseMethod) {
          if (!drugUseMethod.usageMethod || !drugUseMethod.doseUnit || !drugUseMethod.doseValue || !drugUseMethod.freqUnit) {
            this.noUseMethodList.push({ comName: drugFrom['drugUseMethodFroms'][index].comName })
          }
          drugUseMethodList.push(drugUseMethod)

          if (drugUseMethod.usageMethod == '嚼服') {
            drugUseMethod.usageMethod = '咀嚼口服'
          }

          // 判断用法用量是否一致doseValue
          if (drugUseMethod && drugFrom['drugUseMethodFroms'][index] &&
            (drugUseMethod.usageMethod != drugFrom['drugUseMethodFroms'][index].usage || drugUseMethod.doseValue != drugFrom['drugUseMethodFroms'][index].doseValue ||
              drugUseMethod.doseUnit != drugFrom['drugUseMethodFroms'][index].doseUnit || drugUseMethod.freqUnit != drugFrom['drugUseMethodFroms'][index].frequencyUnit)) {
            if (drugUseMethod.doseValue && drugUseMethod.freqUnit) {
              showUpdate = true
              // 建议提醒
              let syAge = ''
              const ageSUnit = drugUseMethod.minAgeUnit == 1 ? '岁' : drugUseMethod.minAgeUnit == 2 ? '月' : ''
              const ageEUnit = drugUseMethod.maxAgeUnit == 1 ? '岁' : drugUseMethod.maxAgeUnit == 2 ? '月' : ''
              if (drugUseMethod.ageS && drugUseMethod.ageE) {
                syAge = `${drugUseMethod.ageS + ageSUnit} ~ ${drugUseMethod.ageE + ageEUnit}`
              } else if (!drugUseMethod.ageS && !drugUseMethod.ageE) {
                syAge = '全年龄段'
              } else if (drugUseMethod.ageS && !drugUseMethod.ageE) {
                syAge = drugUseMethod.ageS + ageSUnit + '以上'
              } else if (!drugUseMethod.ageS && drugUseMethod.ageE) {
                syAge = drugUseMethod.ageE + ageEUnit + '以下'
              }
              drugUseMethod.syAge = syAge
              // let html = '【<span style="color:#fd9c12">' + drugUseMethod.commDrug + '</span>】：适用年龄：' + syAge
              // if (drugUseMethod.diagType) {
              //   html += '，' + drugUseMethod.diagType
              // }
              // html += '<br>使用频次：' + drugUseMethod.freqUnit + '，使用剂量：' + drugUseMethod.doseValue + drugUseMethod.doseUnit
              // if (drugUseMethod.doseLimitHigh && drugUseMethod.doseLimitHigh > drugUseMethod.doseValue) {
              //   html += '，最大用量：' + drugUseMethod.doseLimitHigh + drugBase ? drugBase.smallestUnit : ''
              // }
              // if (drugUseMethod.usageMethod != drugFrom['drugUseMethodFroms'][index].usage) {
              //   html += '<br>建议用法：【' + drugUseMethod.usageMethod + '】'
              // }
              druguseTip.push(drugUseMethod)
            }
          }
        } else {
          this.noUseMethodList.push({ comName: drugFrom['drugUseMethodFroms'][index].comName })
        }

        // const cls = 'drug-btna'
        if (index == 0) {
          this.testResults.isUserName = isUserName
          this.testResults.isDiagnoses = isDiagnoses
          // cls = 'drug-btna cur';
        }
        const drugKeys = index + 1
        if (drugBase) {
          this.drugActiveList.push({
            labels: '药品' + drugKeys,
            drugBase: drugBase || {},
            sex: sex,
            age: age,
            isDisableChildren: isDisableChildren,
            isDisableOld: isDisableOld,
            isDisableWoman: isDisableWoman,
            isDisableGestational: isDisableGestational,
            isLiverDamage: isLiverDamage,
            isRenalDamage: isRenalDamage,
            isDisableAllergy: isDisableAllergy
          })
        }

        if (sex.indexOf(this.currentData.patient_gender == 1 ? '男' : '女') === -1 || isSex == 0) {
          this.testResults.isSex = 0
        }
        if (isAge != 1 && this.testResults.isAge != 0) {
          this.testResults.isAge = isAge
        }
        if (needPrescription == 1) {
          this.testResults.needPrescription = needPrescription
        }
        const lis = [
          'isLiverDamage',
          'isRenalDamage',
          'isDisableChildren',
          'isDisableOld',
          'isDisableWoman',
          'isDisableGestational'
        ]
        lis.forEach((key) => {
          if (item[key] != 0 && this.testResults[key] != 2) {
            this.testResults[key] = item[key]
          }
        })
      })
      this.showUpdate = showUpdate
      this.useError = useError
      // if (druguseTip.length > 0) {
      //   const html1 = '处理建议：推荐用法用量与已维护药品不一致，可鼠标悬停药品，核对好用法用量频次，根据实际情况及需要进行调整！'
      //   druguseTip.push(html1)
      // }
      this.druguseTip = druguseTip
      if (drugUseMethodList && drugUseMethodList.length > 0) {
        this.drugUseMethodList = drugUseMethodList
        if (this.useforbidList && this.useforbidList.length > 0) {
          this.useforbidList.forEach(item => {
            const obj = drugUseMethodList.find(fItem => fItem.commDrug == item.drugName)
            if (obj) {
              item.drugCheckMethod = obj[0]
            }
          })
        }
      }

      const messageReminder = checkList && checkList.length > 0 ? checkList[0].messageReminder : []
      if (messageReminder.length > 0) {
        this.pass = 2
      }

      if (patientInfo.gang != '正常' && this.testResults.isLiverDamage != 0) {
        if (this.testResults.isLiverDamage == 2) {
          this.pass = 2 // 肝检测不通过
        }
      }
      // const RenalTypehtml = ''
      if (patientInfo.gang != '正常' && this.testResults.isRenalDamage != 0) {
        if (this.testResults.isRenalDamage == 2) {
          this.pass = 2 // 检测不通过
        }
      }
      if (patientInfo.pregnancy != '否认') {
        if (this.testResults.isDisableWoman == 2) {
          this.pass = 2 // 检测不通过
        }
        if (this.testResults.isDisableGestational == 2) {
          this.pass = 2 // 检测不通过
        }
      }
      if (this.testResults.isSex == 0) {
        this.pass = 2 // 检测不通过
      }
      if (this.testResults.isAge == 0) {
        this.pass = 2 // 检测不通过
      }
      let age = this.currentData.age
      if (age.indexOf('岁') != -1) {
        age = age.split('岁')[0]
      }
      if (age.indexOf('月') != -1) {
        age = 0
      } else {
        if (age.indexOf('天') != -1) {
          age = 0
        }
      }
      this.checkDignose = 1
    },
    getError () {
      let drugnames = ''
      const error = []
      const warnings = []
      const patientInfo = this.patientInfo
      const drugList = this.currentData.drugs
      // const imageList = this.currentData.imageList
      const checkList = this.checkList
      let age = this.currentData.age
      // 年龄的判断
      if (age.indexOf('岁') != -1) {
        age = age.split('岁')[0]
      }
      if (age.indexOf('月') != -1) {
        age = 0
      } else {
        if (age.indexOf('天') != -1) {
          age = 0
        }
      }

      if (age <= 14 && age >= 6) {
        // xgptUserInfo('age')
        this.isChild = 1
        warnings.push('<span class="c-warning">儿童，注意用量</span>')
      }

      if (age >= 65) {
        this.isOldPeople = 1
        warnings.push('<span class="c-warning">老人，注意用量</span>')
      }

      if (patientInfo.pregnancy != '否认') {
        warnings.push('<span class="c-warning">备孕/怀孕/哺乳期，注意用药</span>')
      }
      // if (patientInfo.allergyContent && patientInfo.allergyContent != '无') {
      //   warnings.push('<span class="c-warning">用户填写信息中有过敏史，请谨慎开方</span>')
      // }

      // 判断药品后-》检查用户年龄信息
      // if (imageList.length > 0) {
      //   warnings.push('<span class="c-warning">用户上传强制凭证,请认真审核。</span>')
      // }
      if (this.testResults.isUserName == 1) {
        error.push('名字异常，请重新确认')
      }
      let isNurseTypeTips = false
      let ismanxing = false
      let manxingName = ''
      const isgnqTips = []
      let isjwzz = false
      let jwzzName = ''
      const diagnoseInfo = this.currentData.custom_diagnose || this.currentData.diagnose_info
      const customDiagnose = diagnoseInfo.split(';')
      customDiagnose.forEach((item) => {
        if (item.indexOf('1型糖尿病') != -1 || item.indexOf('胰腺炎') != -1) {
          this.isjinzhi.push(item)
        }

        if ((item.indexOf('慢性') != -1 && item.indexOf('急性发作') == -1) ||
          item.indexOf('术后') != -1 || item.indexOf('术后伤口感染') != -1 ||
          item.indexOf('细菌性感染') != -1 || item.indexOf('皮肤感染') != -1) {
          ismanxing = true
          manxingName = item
        }

        if (
          item.indexOf('消化道出血') != -1 ||
          item.indexOf('胃出血') != -1 ||
          item.indexOf('肠梗阻') != -1 ||
          item.indexOf('肺出血') != -1 ||
          item.indexOf('脑出血') != -1 ||
          item.indexOf('心肌梗死') != -1 ||
          item.indexOf('脑梗塞') != -1
        ) {
          isjwzz = true
          jwzzName = item
        }

        if (
          (item.indexOf('早产') != -1 ||
            item.indexOf('产科') != -1 ||
            item.indexOf('先兆流产') != -1 ||
            item.indexOf('孕期') != -1 ||
            item.indexOf('胎动') != -1 ||
            item.indexOf('安胎') != -1 ||
            item.indexOf('孕妇') != -1 ||
            (item.indexOf('孕') != -1 && item.indexOf('周') != -1)) && patientInfo.pregnancy == '否认'
        ) {
          isNurseTypeTips = true
        }

        if (
          item.indexOf('更年期') != -1 && age < 45
        ) {
          isgnqTips.push(item)
        }
      })

      if (isNurseTypeTips) {
        const htmlText = '请核实怀孕状态，如确认怀孕请将个人信息是否备孕/怀孕/哺乳期修改为”是”。'
        error.push(htmlText)

        // 将孕哺情况的文字标红
        // xgptUserInfo('ypqk')
      }

      if (isgnqTips && isgnqTips.length > 0) {
        const isgnqTipstr = isgnqTips.join(',')
        const htmlText = `诊断【${isgnqTipstr}】不符合年龄，请核对！`
        error.push(htmlText)

        // 将孕哺情况的文字标红
        // xgptUserInfo('age')
      }
      // let disListxt = disList.join(',')
      let iskangshengsu = false
      this.kfhMust = []
      drugList.forEach((item, index) => {
        if ((item.usage.indexOf('阴道') != -1 || item.usage.indexOf('外阴') != -1) && this.currentData.patient_gender == '1') {
          if (drugnames) {
            drugnames += ',' + item.drugName
          } else {
            drugnames = item.drugName
          }
        }
        if (
          item.drugName.indexOf('头孢') != -1 ||
          item.drugName.indexOf('阿莫西林') != -1 ||
          item.drugName.indexOf('甲硝唑') != -1 ||
          item.drugName.indexOf('左氧氟沙星') != -1 ||
          item.drugName.indexOf('沙星') != -1
        ) {
          iskangshengsu = true
        }
      })
      if (drugnames) {
        const htmlText = `${drugnames}用药方式与性别不符，请修改;`
        error.push(htmlText)
      }
      // 判断有抗生素药品，并且诊断有慢性内容，需要拦截
      if (ismanxing == true && iskangshengsu == true) {
        error.push(`【${manxingName}】无感染指征，不建议用抗生素，请核对`)
      }

      // 判断有危重病症，需要拦截
      if (isjwzz == true) {
        error.push(`【${jwzzName}】属于急危重症，如需开具，请尝试修改轻症的诊断名。`)
      }
      if (checkList && checkList.length > 0) {
        checkList.forEach(item => {
          // if (item.drugDiagnoses.length == 0) error.push(`【${item.comName}】缺少诊断`)
          const messageReminder = item.messageReminder
          const repeatDrugs = item.repeatDrugs
          if (repeatDrugs && repeatDrugs.length > 0) {
            repeatDrugs.forEach(fItem => {
              const repeatStr = `${item.comName}和${fItem}属于重复用药，不推荐合用`
              error.push(repeatStr)
            })
          }
          if (messageReminder && messageReminder.length) {
            messageReminder.forEach((items) => {
              if (!error.some(item => item.includes(items))) error.push(items)
              if (items.indexOf('属于高风险药品') != -1) {
                const drugname2 = items.split('属高风险药品')[0]
                const drinkFlags3 = this.drinkFlags3
                const drinkFlags2txt = drinkFlags3.join(' ')
                if (
                  drinkFlags3.indexOf(items) == -1 &&
                  drinkFlags2txt.indexOf(drugname2) == -1
                ) {
                  this.drinkFlags3.push(items)
                }
              }
            })
          }
          const focuses = item.focuses
          if (focuses && focuses.length) {
            this.focuses = focuses
            focuses.forEach((items) => {
              warnings.push(`<span class="c-warning">${items}</span>`)
            })
          }
          if (item.isSex == 0) {
            const textTip = `${item.comName}与用药人性别不符`
            error.push(textTip)
          }
          if (item.isAge == 0) {
            const textTip = `${item.comName}与用药人年龄不符`
            error.push(textTip)
          }
        })
      }
      if (patientInfo.pregnancy != '否认') {
        const hasnurse = []
        checkList.forEach((litem) => {
          if ((litem.isDisableGestational == 1 || litem.isDisableWoman == 1) && litem.drugBase) {
            hasnurse.push(litem.drugBase.commDrug)
          }
          if (litem.isDisableWoman == 2) {
            error.push(`${litem.comName}为妊娠孕妇禁用药品`)
          }
          if (litem.isDisableGestational == 2) {
            error.push(`${litem.comName}为哺乳期禁用药品`)
          }
        })
      }
      // 诊断与药品适应症/用户性别不匹配
      const diseaseList = []
      const sexList = []
      for (let i = 0; i < this.testResults.isDiagnoses.length; i++) {
        const item = this.testResults.isDiagnoses[i]
        if (item.isDisease != 1) diseaseList.push(item.diseaseName)
        if (item.isSexUnusual != 1) sexList.push(item.diseaseName)
      }
      if (diseaseList.length > 0) {
        const diseaseStr = diseaseList.join('/')
        const advisesTip = diseaseStr + '诊断与药品适应症不匹配'
        warnings.push(`<span class="c-warning">${advisesTip}</span>`)
        this.checkDignose = 0
      }
      if (sexList.length > 0) {
        const diseaseStr = diseaseList.join('/')
        const advisesTip = diseaseStr + '诊断与用户性别不匹配'
        error.push(advisesTip)
        this.checkDignose = 0
      }

      this.showError = [...new Set(error)]
      this.showYellow = [...new Set(warnings)]
      // 判断风险等级，存于store
      // const zdforbidState = this.zdforbidState
      // const qzfangxing = this.qzfangxing
      // const isAllergy = this.currentData.patientInfo.allergyContent && this.currentData.patientInfo.allergyContent != '无'
      // const pauseType = this.pauseType
      if (this.useforbidList.length > 0 || error.length > 0) {
        // 此处处理为禁止开方
        this.forbidLevel = 0
      } else {
        if (this.isOldPeople || this.isChild || this.focuses.length > 0 || this.excessList.length > 0 || this.noUseMethodList.length > 0 || this.outDoseList.length > 0 || this.outFreList.length > 0 || this.druguseTip && this.druguseTip.length > 0) {
          // 重点关注
          this.forbidLevel = 3
        } else {
          this.forbidLevel = 1
        }
      }
      this.checkDone = 1
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-card__body {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.drug-name {
  color: #fd9c12;
  font-weight: bold;
}

.line-row {
  height: 1px;
  background-color: rgba(224, 224, 224, 1);
  margin: 6px 0;
}

/deep/ .el-backtop {
  width: 48px;
  height: 70px;
  font-size: 12px;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}
.back-top {
  text-align: center;
  .el-image {
    background-color: #fff;
  }
}
.aide-check-item[data-subtitle]:before {
  content: attr(data-subtitle);
  display: inline-block;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  background-color: #fff;
  position: relative;
  z-index: 1;
  line-height: 24px;
  margin: 8px 0;
}
.aide-check-item[data-subtitle] {
  position: relative;
}

.no-margin-top[data-subtitle]:before {
  margin-top: 0;
}

.aide-check-item {
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  &.no-border {
    border: none;
  }
  .title-left {
    text-align: left;
  }
  .title-right {
    text-align: right;
    float: right;
    padding-right: 6px;
    //background: #e02020;
    //color: #000;
  }
}

.aide-home {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  .drug-use-method {
    display: flex;
    & > div {
      flex: 1;
    }
  }
  .check-title {
    margin: 10px 0;
  }
  .send-box {
    text-align: right;
    float: right;
    .check-click {
      margin-left: 20px;
      color: #f07503;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .check-box {
    border: 1px solid rgba(216, 216, 216, 1);
    border-radius: 8px;
    padding: 12px;
  }
  .unpass {
    color: #e02020;
  }
  .pass {
    color: #66cc66;
  }
  .c-error {
    color: #e20000;
  }
  .c-warning {
    color: #fd9c12;
  }
  .mgtb12 {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .line-left {
    text-align: right;
    font-weight: 600;
    height: 32px;
    line-height: 32px;
    padding-right: 16px;
    border-top: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
  }
  .line-right {
    height: 32px;
    line-height: 18px;
    text-align: left;
    padding: 12px 12px 12px 18px;
    border-top: 1px solid #d8d8d8;
  }
  .drug-usage-dosage {
    overflow: hidden;
    .check-use-method-list {
      &:last-of-type {
        margin-bottom: 0px;
      }
      margin-bottom: 18px;
      .check-use-method {
        .check-drug-name {
          font-weight: bold;
        }
      }
    }
    .check-operate {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #3b94ff;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      height: 40px;
      cursor: pointer;
      i {
        margin-right: 12px;
      }
    }
  }
  .check-item {
    .new-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      color: #e20000;
      .new-title-right {
        display: flex;
        align-items: center;
        .el-image {
          margin-top: 2px;
          margin-left: 10px;
        }
      }
    }
  }

  .aide-home-result {
    text-align: left;
    .aide-home-checking {
      font-weight: 600;
      .el-icon-loading {
        margin-left: 10px;
      }
    }
    .aide-home-done {
      text-align: left;
      .result-done {
        font-weight: 600;
      }
      .result-top {
        text-align: center;
        background-color: #ffe5e5;
        height: 132px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 8px;
        .result-check {
          display: flex;
          justify-content: center;
          align-items: center;
          .result-logo {
            text-align: center;
          }
          .result-text {
            font-size: 20px;
            font-weight: 600;
            margin-left: 10px;
          }
        }
        .button-box {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          .update-use {
            cursor: pointer;
            width: 132px;
            height: 36px;
            background: #f4faff;
            border: 1px solid rgba(59, 148, 255, 1);
            border-radius: 5px;
            font-size: 16px;
            color: #2d85ee;
            line-height: 36px;
          }
          .cxjc {
            cursor: pointer;
            width: 100px;
            height: 36px;
            line-height: 36px;
            background: #f4faff;
            border: 1px solid rgba(59, 148, 255, 1);
            border-radius: 5px;
            margin-left: 10px;
            font-size: 16px;
            color: #2d85ee;
          }
        }
      }
      .result-list {
        text-align: left;
        .result-reason {
          color: #e02020;
        }
        .result-warnig {
          color: #fd9c12;
          // margin-top: 5px;
        }
      }
    }
  }
  .aide-home-check {
    text-align: left;
    .aide-title {
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .aide-check-body {
      .check-user,
      .check-dignose {
        .check-col {
          display: flex;
          justify-content: space-between;
          padding-right: 12px;
          height: 24px;
          line-height: 24px;
        }
        .check-left {
          text-align: left;
          flex: 1;
        }
        .check-right {
          text-align: right;
        }
      }
      .advise-result-list {
        line-height: 24px;
        .usewenti {
          line-height: 24px;
        }
        .jcFx {
          text-align: right;
          color: #3b94ff;
          padding-right: 6px;
          cursor: pointer;
        }
        .wtFx {
          text-align: right;
          color: #3b94ff;
          padding-right: 6px;
          cursor: pointer;
        }
      }
    }
  }
  .aide-home-drug {
    margin-bottom: 20px;
    text-align: left;
    .aide-title {
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .aide-check-body {
      padding: 0;
      .drug-active {
        padding-top: 12px;
        padding-left: 32px;
      }
      /deep/ .el-tabs__header {
        margin-top: 2px;
      }
      /deep/ .el-tabs__item {
        color: #333;
        font-weight: 400;
        height: 24px;
        line-height: 24px;
      }
      /deep/ .el-tabs__item.is-active {
        color: #3b94ff;
      }
      /deep/ .el-tabs__active-bar {
        background-color: #3b94ff;
      }
      /deep/ .el-tabs__nav-wrap::after {
        background: #fff;
      }
      .drug-list {
        .drug-detail {
          font-size: 12px;
          .drug-img {
            text-align: right;
            padding: 16px;
          }
          .drug-desc {
            text-align: left;
            font-size: 12px;
            padding-left: 18px;
            .desc-title {
              font-weight: 600;
              margin-bottom: 10px;
            }
            .desc-text {
              margin-bottom: 5px;
            }
          }
          .text-left {
            text-align: right;
            height: 32px;
            line-height: 32px;
            font-weight: 600;
            padding-right: 16px;
          }
          .text-right {
            text-align: left;
            height: 32px;
            line-height: 32px;
            padding-left: 18px;
          }
        }
      }
    }
  }
}
</style>
