<template>
  <div class="inquiry-spec">
    <div class="search">
      <!-- <el-select v-model="searchData.level"
                 size="mini"
                 style="width: 100px;margin-right: 10px;">
        <el-option v-for="(item,index) in levelList"
                   :key="index"
                   :label="item.name"
                   :value="item.id">
        </el-option>
      </el-select> -->
      <el-input v-model="searchData.speechKey"
                :placeholder="$t('consultRoom.searchWordTip')"
                size="mini"
                clearable />
      <el-button size="mini"
                 type="primary"
                 @click="handleSearch">{{ $t('common.inquire') }}</el-button>
    </div>
    <el-tree ref="tree"
             :data="treeList"
             :props="defaultProps"
             :highlight-current="false"
             :auto-expand-parent="false"
             :expand-on-click-node="false"
             :filter-node-method="filterNode"
             accordion>
      <template slot-scope="{ node, data }">
        <div :class="data.id?'':'custom-tree-node'">
          <div class="label">{{ node.label }}</div>
          <div v-if="!data.id">
            <el-button type="text"
                       size="mini"
                       @click="() => createWord(data)">
              {{ $t('consultRoom.addCommon') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-tree>
  </div>
</template>

<script>
import { consultRoomApi } from '@/api/consultRoomApi'

export default {
  data() {
    return {
      activeName: '',
      searchData: {
        level: '1'
      },
      levelList: [
        { id: '1', name: '一级话术' },
        { id: '2', name: '二级话术' }
      ],
      treeList: [],
      defaultProps: {
        label: 'content',
        children: 'children'
      },
      isCreating: false
    }
  },
  methods: {
    // 获取问诊规范
    async fetchData() {
      try {
        this.isLoading = true
        const res = await consultRoomApi.fetchInquirySpec({
          level: this.searchData.level,
          speechKey: this.searchData.speechKey
        })
        this.treeList = res.data.data || []
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        console.error(err)
      }
    },
    filterNode(value, data) {
      if (!value) return true
      return data.content.indexOf(value) !== -1
    },

    // 查询
    handleSearch() {
      if (!this.searchData.speechKey) {
        this.fetchData()
      } else {
        this.$refs.tree.filter(this.searchData.speechKey)
      }
    },
    // 添加常用话术
    async createWord(data) {
      try {
        if (this.isCreating) return
        this.isCreating = true
        await consultRoomApi.createCommonWord({ content: data.content })
        this.$message.success(this.$t('common.operateSucc'))
        this.isCreating = false
      } catch (err) {
        this.isCreating = false
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inquiry-spec {
  overflow-y: auto;
  .search {
    display: flex;
    margin-bottom: 10px;
    .el-input {
      width: 240px;
      margin-right: 10px;
    }
    // /deep/ .el-input__inner {
    //   border-radius: 0;
    // }
    // .el-button {
    //   border-radius: 0;
    // }
  }
}
/deep/ .el-tree {
  height: 570px;
  overflow-y: auto;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  /deep/ .el-tree-node {
    overflow: hidden;
  }
  .label {
    width: 450px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
